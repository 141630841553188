import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import xIcon from "../Assets/Icon/x@2x.png";
import classNames from "classnames";
import "./ModalButton.css";

const ModalButton = (props) => {
  const {
    btnLabel,
    btnClassname,
    modalTitle,
    children,
    showModal,
    setShowModal,
  } = props;

  const handleShow = () => {
    setShowModal(true);
  };

  const handleHide = () => {
    setShowModal(false);
  };

  return (
    <>
      <button
        onClick={handleShow}
        className={classNames("button-open-modal favorite-btn", btnClassname)}
      >
        {btnLabel}
      </button>
      <Modal show={showModal} onHide={handleHide} dialogClassName="modalDims">
        <div className="modalHeader">
          <div className="modalTitle">
            <span>{modalTitle}</span>
            <button onClick={handleHide} className="modalCloseButton">
              <img src={xIcon} className="xIcon" alt="close" />
            </button>
          </div>
        </div>
        <div className="modalBody">{children}</div>
      </Modal>
    </>
  );
};

ModalButton.propTypes = {
  btnLabel: PropTypes.string.isRequired,
  btnClassname: PropTypes.string,
  modalTitle: PropTypes.string.isRequired,
  children: PropTypes.node,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default ModalButton;
