import React, { useState } from "react";
import PropTypes from "prop-types";
import ModalButton from "../../Components/ModalButton";
import { removeCompanyFromFavorite } from "../../utils/router";

import "../../Components/ModalButton.css";

const FavoriteDelete = (props) => {
  const { user, favorite, onSuccess, onSubmit, options } = props;

  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleHide = () => {
    setShow(false);
  };

  const handleSubmit = async () => {
    const { token, companyName } = user;
    let isDeleted = false;
    if (onSubmit) {
      isDeleted = await onSubmit(companyName, favorite, setErrorMessage);
    } else {
      isDeleted = await removeCompanyFromFavorite(
        token,
        companyName,
        favorite,
        setErrorMessage
      );
    }
    if (isDeleted) {
      handleHide();
      onSuccess();
    }
  };

  return (
    <ModalButton
      btnLabel="Delete"
      btnClassname="favorite-delete"
      modalTitle={options?.customTitle || "Delete Favorite"}
      showModal={show}
      setShowModal={setShow}
    >
      <div className="favorite-delete-modal">
        {errorMessage && <div className="formError">{errorMessage}</div>}
        <span>
          {options?.customBody ?? (
            <>
              Are you sure you want to completely remove favorite "
              {favorite.favoriteName}" from your available product list?
            </>
          )}
        </span>
        <div className="favorite-delete-btns">
          <button
            className="button-open-modal favorite-btn"
            onClick={handleHide}
          >
            No
          </button>
          <button
            className="button-open-modal favorite-btn"
            onClick={handleSubmit}
          >
            Yes
          </button>
        </div>
      </div>
    </ModalButton>
  );
};

FavoriteDelete.propTypes = {
  user: PropTypes.object.isRequired,
  favorite: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default FavoriteDelete;
