import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import FavoritesTable from "./FavoritesTable";
import CustomInput from "../Components/CustomInput";
import { sortFavorites, tableKeyTitles } from "./utils";
import usePharmacyNames from "../hooks/usePharmacyNames";

import "./Favorites.css";

const Favorites = (props) => {
  const { user, error, favorites, handleFetch } = props;

  const [medIdFilter, setMedIdFilter] = useState("");
  const { pharmacyNames } = usePharmacyNames();

  useEffect(() => {
    handleFetch();
  }, []);

  const handleMedIdFilterChange = (e) => {
    setMedIdFilter(e.target.value);
  };

  const favoriteList = sortFavorites(favorites).filter((fav) => {
    if (!medIdFilter) return true;
    return fav.clientName.includes(medIdFilter);
  });

  const rowOverrides = {
    allowSubstitutions: (value) => (
      <Form.Check disabled defaultChecked={value} />
    ),
  };

  return (
    <div className="favorites-page">
      {error === "" ? (
        <>
          <CustomInput
            type="text"
            name="clientName"
            placeholder="Search by Medication ID"
            onChange={handleMedIdFilterChange}
          />
          <FavoritesTable
            user={user}
            favorites={favoriteList}
            getRowKey={(element) => element._id}
            tableKeyTitles={tableKeyTitles}
            onSuccess={handleFetch}
            rowOverrides={rowOverrides}
            pharmacies={pharmacyNames}
          />
        </>
      ) : (
        <div className="error-container">
          <h1>{error}</h1>
        </div>
      )}
    </div>
  );
};

Favorites.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Favorites;
