import React from "react";
import PropTypes from "prop-types";
import "./CustomInput.css";

const CustomInput = (props) => {
  const { type = "text", placeholder, onChange, onKeyUp = null } = props;

  return (
    <div className="customInputContainer">
      <input
        className="customInput"
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onKeyUp={onKeyUp}
      />
    </div>
  );
};

CustomInput.propTypes = {
  type: PropTypes.oneOf(["text", "password"]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default CustomInput;
