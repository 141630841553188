import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import CustomTable from "../Components/CustomTable";
import PendingFavoriteCreate from "./Components/PendingFavoriteCreate";
import PendingFavoriteUpdate from "./Components/PendingFavoriteUpdate";
import FavoriteDelete from "./Components/FavoriteDelete";
import { sortFavorites, tableKeyTitles, isCompanyBask } from "./utils";

const FavoritesTable = (props) => {
  const { user, favorites = [], onSuccess = () => {}, pharmacies = {} } = props;
  const visitTypes = user.visitTypes;

  const favoriteList = sortFavorites(favorites);

  const isNotBask = !isCompanyBask(user.companyName);
  const titleOverrides = {
    createUpdate:
      isNotBask &&
      (() => (
        <PendingFavoriteCreate
          user={user}
          onSuccess={onSuccess}
          visitTypes={visitTypes}
          pharmacies={pharmacies}
        />
      )),
  };

  const rowOverrides = {
    createUpdate:
      isNotBask &&
      ((_, favorite) => (
        <div className="favorite-button-container">
          {favorite.type === "supply" ? null : (
            <PendingFavoriteUpdate
              user={user}
              favorite={favorite}
              onSuccess={onSuccess}
              visitTypes={visitTypes}
              pharmacies={pharmacies}
            />
          )}
          <FavoriteDelete
            user={user}
            favorite={favorite}
            onSuccess={onSuccess}
          />
        </div>
      )),
    allowSubstitutions: (value) => (
      <Form.Check disabled defaultChecked={value} />
    ),
  };

  return (
    <div className="favorites-page">
      <CustomTable
        list={favoriteList}
        getRowKey={(element) => element._id}
        tableKeyTitles={tableKeyTitles}
        titleOverrides={titleOverrides}
        rowOverrides={rowOverrides}
      />
    </div>
  );
};
FavoritesTable.propTypes = {
  user: PropTypes.object.isRequired,
  favorites: PropTypes.array.isRequired,
  onSuccess: PropTypes.func,
};

export default FavoritesTable;
