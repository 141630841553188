/**
 *
 * @template T
 * @param {T[]} array - array of items to group
 * @param {number} groupSize
 * @returns {T[][]}
 * @example
 * const array = [1, 2, 3, 4, 5, 6, 7, 8];
 * const groupSize = 3;
 * const groupedArray = groupBy(array, groupSize);
 * console.log(groupedArray);
 * // [[1, 2, 3], [4, 5, 6], [7, 8]];
 */
export function groupBy(array, groupSize = 1) {
  return array.reduce((acc, item, index) => {
    if (index % groupSize === 0) {
      acc.push([item]);
    } else {
      acc[acc.length - 1].push(item);
    }

    return acc;
  }, []);
}
