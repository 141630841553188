import React, { useState } from "react";

import bigLogo from "../Assets/bigLogo.png";

import "./Login.css";
import CustomInput from "../Components/CustomInput";
import { login } from "../utils/router";

const Login = (props) => {
  const { setUserInfo } = props;
  const [loginData, setLoginData] = useState({});
  const [message, setMessage] = useState("");

  const handleChange = (key, value) => {
    setLoginData({
      ...loginData,
      [key]: value,
    });
  };

  const handleApiKeyDashboardChange = (e) => {
    handleChange("apiKeyDashboard", e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { apiKeyDashboard } = loginData;
    await login(e, apiKeyDashboard, setMessage, setUserInfo);
  };

  return (
    <div className="loginContainer">
      <div className="middleRectangle">
        <div className="logoContainer">
          <img src={bigLogo} alt="Beluga Health Logo" className="loginLogo" />
        </div>
        {message}
        <form className="loginForm" onSubmit={handleSubmit}>
          <div className="loginText">Log In</div>
          <CustomInput
            type="password"
            placeholder="Dashboard API Key"
            onChange={handleApiKeyDashboardChange}
          />
          <button type="submit" className="loginButton">
            Log In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
