import React from "react";
import PropTypes from "prop-types";
import FavoriteModalFormButton from "./FavoriteModalFormButton";
import { addOrEditFavorite } from "../../utils/router";

const PendingFavoriteUpdate = (props) => {
  const {
    user,
    favorite,
    visitTypes,
    onSuccess,
    pharmacies,
    options,
    onSubmit,
  } = props;

  const handleFormSubmit = async (formData, setErrorMessage) => {
    if (onSubmit) {
      return await onSubmit(formData, setErrorMessage);
    }
    const { token } = user;
    const res = await addOrEditFavorite(token, formData, setErrorMessage);
    if (res) {
      return res;
    }
    return false;
  };

  return (
    <FavoriteModalFormButton
      btnTitle="Edit"
      modalTitle={options?.customTitle ?? "Update Details"}
      defaultFormData={favorite}
      onFormSubmit={handleFormSubmit}
      onSuccess={onSuccess}
      visitTypes={visitTypes}
      pharmacies={pharmacies}
      mode="edit"
      customSubmitText={options?.customSubmitText}
    />
  );
};

PendingFavoriteUpdate.propTypes = {
  user: PropTypes.object.isRequired,
  favorite: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  visitTypes: PropTypes.array.isRequired,
};

export default PendingFavoriteUpdate;
