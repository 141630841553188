import React from "react";
import PropTypes from "prop-types";
import CustomTable from "../Components/CustomTable";
import { deletePendingFavorite, updatePendingFavorite } from "../utils/router";
import { tableKeyTitles } from "./utils";
import PendingFavoriteUpdate from "./Components/PendingFavoriteUpdate";
import FavoriteDelete from "./Components/FavoriteDelete";

// when create favorite, BE updates favoriteName to "companyName - favoriteName"
// here we want to show the user only the favoriteName, trip out the companyName part
const stripCompanyName = (favoriteName) => {
  const parts = favoriteName.split(" - ");
  // case 1: for the most case it'll be companyName - favoriteName
  // case 2: for when the favorite name has a "-" in it as well:
  //         "companyName - 'some - favoriteName'"
  if (parts.length >= 2) {
    return parts.slice(1).join(" - ");
  }

  return favoriteName;
};

const PendingFavoritesTable = (props) => {
  const {
    favorites = [],
    acceptReject = () => {},
    user,
    pharmacies,
    onSuccess,
  } = props;

  const visitTypes = user.visitTypes;
  const titleOverrides = {
    createUpdate: "Status",
  };

  const handleFormSubmit = async (formData, setErrorMessage) => {
    const { token } = user;
    const res = await updatePendingFavorite(token, formData, setErrorMessage);
    if (res) {
      return res;
    }
    return false;
  };

  const handleDelete = async (_companyName, favorite, setErrorMessage) => {
    const { token } = user;
    const res = await deletePendingFavorite(token, favorite, setErrorMessage);
    return res;
  };

  const rowOverrides = {
    createUpdate: (_, favorite) => {
      if (favorite.className === "isRejected") {
        return (
          <div>
            <div>Rejected</div>
            <button
              onClick={acceptReject}
              name={favorite.clientName}
              className="acceptButton"
            >
              OK
            </button>
          </div>
        );
      } else {
        // when create favorite, BE updates favoriteName to "companyName - favoriteName"
        // here we want to show the user only the favoriteName, trip out the companyName part
        const { favoriteName, requestingCompany, __v, company, ...rest } =
          favorite;
        const updatedFavorite = {
          ...rest,
          favoriteName: stripCompanyName(favoriteName),
        };
        return (
          <div className="favorite-button-container">
            <PendingFavoriteUpdate
              user={user}
              favorite={updatedFavorite}
              onSuccess={onSuccess}
              visitTypes={visitTypes}
              pharmacies={pharmacies}
              options={{
                customTitle: "Update Pending Favorite",
                customSubmitText: "Update",
              }}
              onSubmit={handleFormSubmit}
            />
            <FavoriteDelete
              user={user}
              favorite={favorite}
              onSuccess={onSuccess}
              onSubmit={handleDelete}
              options={{
                customTitle: "Delete Pending Favorite",
                customBody: (
                  <>
                    Are you sure you want to completely delete the update/create
                    request for "{favorite.favoriteName}"?
                  </>
                ),
              }}
            />
          </div>
        );
      }
    },
  };

  return (
    <div className="favorites-page">
      <CustomTable
        list={favorites}
        getRowKey={(element) => element._id}
        tableKeyTitles={tableKeyTitles}
        titleOverrides={titleOverrides}
        rowOverrides={rowOverrides}
      />
    </div>
  );
};
PendingFavoritesTable.propTypes = {
  favorites: PropTypes.array.isRequired,
  acceptReject: PropTypes.func,
};

export default PendingFavoritesTable;
